<template>
  <div class="product">
    <div v-if="list.searchProductId">
      <div class="product-info">
        <!-- <nav-bar ></nav-bar> -->
        <!-- <div class="download-pannel" v-if="isAppTip">
          <div class="download-banner">
            <div class="download-close" @click="hideTop">
              <img src="@/assets/image/user/icon-close-banner.png" />
            </div>
            <div class="download-logo">
              <img src="@/assets/image/user/e-Rith.png" />
            </div>
            <div class="download-txt">
              <span class="download-content">
                <em class="content-up" style="font-size: 13px; color: #ffffff">{{ $t("打開App購物") }}</em>
              </span>
            </div>
          </div>
          <div class="download-action" @click="handleOpenApp">
            <span class="font-large" style="color: #ffffff">{{
              $t("立即打開")
              }}</span>
          </div>
        </div> -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in list.allPicture" :key="item"><img :src="getProductImageUrl(item,400)"
              alt="" /></van-swipe-item>
          <template #indicator="{ active,total }">
            <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
          </template>
        </van-swipe>
        <div class="product-content">
          <div class="product-content-title">
            <div class="product-name" ref="productName">
              <div class="product-text" ref="productText">
                {{ list.productName }}
              </div>
            </div>
            <img v-if="isShowProductName" @click="handleViewProductName" src="@/assets/icons/down.png" alt="" />
          </div>
          <div class="product-content-unit">
            <span>{{ $t("单位") }}:{{ list.unit }}</span>
            <span class="vertical" v-if="list.containerQuantity">|</span>
            <span>{{
              list.containerQuantity
                ? $t("每箱") + list.containerQuantity + list.unit
                : ""
            }}</span>
          </div>
          <!-- 限时活动或促销 -->
          <div v-if="this.list.promotionDisplay?.countDown == 1"
            :style="list.promotionDisplay.showPriceBackground == 1 ? `background:url(${imgUrl}/${list.promotionDisplay?.picPath}) no-repeat center / cover` : ''"
            class="activity">
            <div class="time" :style="{ color: list.promotionDisplay.textColor }">
              <span>{{ $t('距離結束') }}</span>
              <div class="day">{{ days }}</div>
              <span>{{ $t('天') }}</span>
              <div class="hour">{{ sumZore(hours) }}</div>
              <span>:</span>
              <div class="minute">{{ sumZore(minutes) }}</div>
              <span>:</span>
              <div class="second">{{ sumZore(count) }}</div>
            </div>
            <div class="amountmoney" v-if="list.isAuth">
              <div class="dis-price">
                <div class="price">{{ formatNumber(list.selectStandard.discountPrice) }}</div>
                <div class="discounts"><span>{{ list.discount }}</span>%OFF</div>
              </div>
              <div class="original" v-if="list.isDiscount">{{ $t('原價') }}：{{ formatNumber(list.selectStandard.price) }}
              </div>
            </div>
            <div class="price-text" v-else>
              {{ list.text }}
            </div>
          </div>
          <div v-else>
            <div class="product-content-price" v-if="list.isAuth">
              <div class="discounts-price">
                {{ formatNumber(list.selectStandard.discountPrice) }}
              </div>
              <div class="original-price" v-if="list.isDiscount">
                {{ formatNumber(list.selectStandard.price) }}
              </div>
            </div>
            <div class="price-text" v-else>
              {{ list.text }}
            </div>
          </div>
          <div class="box-tag" v-if="this.list?.tags.length || this.list?.promotes.length">
            <!-- <span class="tag" @click="handleopenfullgift">{{ $t('滿贈') }}</span> -->
            <span class="tag" v-for="(item,index) in this.list?.tags" :key="index">{{ item.title }}</span>
          </div>
        </div>
        <!-- 套裝包含產品 -->
        <div class="product-suit" v-if="list.productType === 6">
          <div class="specification-title">
            {{ $t("套裝包含產品") }}
            <div class="more" @click="handleOpenSuit">
              <img src="@/assets/icons/arrow-rightactive.png" alt="" />
            </div>
          </div>
        </div>
        <div class="product-specification">
          <div class="specification-title" @click="handleBuyProduct(1)">
            {{ $t("商品規格") }}
            <img src="@/assets/icons/arrow-rightactive.png" alt="" />
          </div>
          <div class="specification-list">
            <div class="phone-list">
              <div :class="['phone-item',{ actvie: item.id == selectPhone }]" v-for="item in list.allStandard"
                :key="item.id" @click="handleAreaCode(item)">
                <div class="phone-text">{{ item.name }}</div>
                <img class="phone-check" v-if="item.id == selectPhone" src="@/assets/icons/check.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="getcoupon">
          <div class="specification-title">
            {{ $t("領取優惠券") }}
            <div class="more" @click="handleopenfullgift">
              {{ $t("更多") }}
              <img src="@/assets/icons/arrow-rightactive.png" alt="" />
            </div>
          </div>
          <div class="coupon-list">
            <div class="coupon-list-item">
              <limitCouponLayout :list="canGetCouponsNew" :isAll="false" :contentStyle="'margin-right: 12px;'"
                @success="handleSuccess" />
            </div>
          </div>
        </div>
        <div class="product-argument">
          <div class="specification-title">
            {{ $t("商品參數") }}
            <div class="more" @click="handleOpenArgument">
              {{ $t("更多") }}
              <img src="@/assets/icons/arrow-rightactive.png" alt="" />
            </div>
          </div>
          <div class="argument-list">
            <div class="argument-container">
              <div class="argument-item">
                <div class="argument-label">
                  <div class="argument-label-pd">{{ $t("商品名") }}</div>
                </div>
                <div class="argument-content">{{ list.name }}</div>
              </div>
              <div class="argument-item">
                <div class="argument-label">
                  <div class="argument-label-pd">{{ $t("生產企業") }}</div>
                </div>
                <div class="argument-content">
                  {{ list.producer || "-" }}
                </div>
              </div>
              <div class="argument-item">
                <div class="argument-label">
                  <div class="argument-label-pd">{{ $t("有效期至") }}</div>
                </div>
                <div class="argument-content">
                  {{ list.expiredDate || "-" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-detail-img">
          <div class="specification-title">
            {{ $t("商品详情") }}
          </div>
          <div class="product-page" v-html="list.page"></div>
        </div>
      </div>

      <div class="product-detail-footer">
        <div class="product-btn-left">
          <div class="product-service">
            <img src="@/assets/image/order/service.png">
            <div class="name">{{ $t('客服') }}</div>
          </div>
          <div class="product-cart" @click="handlegoCart">
            <img src="@/assets/image/order/cart.png">
            <div class="name">{{ $t('購物車') }}</div>
            <div class="badge" v-if="cartCount">{{ cartCount }}</div>
          </div>
        </div>
        <div class="product-detail-footer-buy" v-if="!list.isAuth" @click="gotoLogin">
          {{ list.text }}
        </div>
        <div class="product-detail-footer-buy" v-else @click="handleBuyProduct(2)">
          {{ $t("立即購買") }}
        </div>
        <div class="product-detail-footer-addCart" @click="handleBuyProduct(3)">
          {{ $t("加入購物車") }}
        </div>
      </div>
    </div>
    <van-popup v-model:show="isArgument" position="bottom" :style="{ height: '90%' }" :close-on-click-overlay="false"
      round>
      <div class="Argument-dialog">
        <img class="close" @click="isArgument = false" src="@/assets/icons/ic_Close@2x.png" alt="" />
        <div class="Argument-header">{{ $t("商品參數") }}</div>
        <div class="argument-list">
          <div class="argument-container">
            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd" style="padding: 20px;">
                  {{ $t("商品名") }}
                </div>
              </div>

              <div class="argument-content">{{ list.name }}</div>
            </div>
            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("規格型號") }}</div>
              </div>
              <div class="argument-content">{{ list.selectStandard.name }}</div>
            </div>
            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("商品編號") }}</div>
              </div>
              <div class="argument-content">{{ list.productCode }}</div>
            </div>

            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("類別") }}</div>
              </div>
              <div class="argument-content">
                {{ list.firstCName + "/" + list.secondCName }}
              </div>
            </div>
            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("單位") }}</div>
              </div>
              <div class="argument-content">{{ list.unit || "-" }}</div>
            </div>

            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("整箱數量") }}</div>
              </div>
              <div class="argument-content">
                {{ list.containerQuantity || "-" }}
              </div>
            </div>
            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("品牌") }}</div>
              </div>
              <div class="argument-content">
                {{ list.brandName || "-" }}
              </div>
            </div>
            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("生產企業") }}</div>
              </div>
              <div class="argument-content">
                {{ list.producer || "-" }}
              </div>
            </div>
            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("CE代碼") }}</div>
              </div>
              <div class="argument-content">
                {{ list.ceCode || "-" }}
              </div>
            </div>
            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("FDA代碼") }}</div>
              </div>
              <div class="argument-content">
                {{ list.fdaCode || "-" }}
              </div>
            </div>
            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("生產日期") }}</div>
              </div>
              <div class="argument-content">
                {{ list.selectStandard.productionDate ? formatDate(list.selectStandard.productionDate) : "-" }}
              </div>
            </div>
            <div class="argument-item">
              <div class="argument-label">
                <div class="argument-label-pd">{{ $t("有效期至") }}</div>
              </div>
              <div class="argument-content">
                {{ list.selectStandard.expiredDate ? formatDate(list.selectStandard.expiredDate) : "-" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <specificationDialog v-if="isSpecificationDialog1" @handleConfirm="handleConfirm" :isAuth="isAuth"
      @handleClose="handlespecificationClose" :show="isSpecificationDialog" :searchProductId="detailId"
      :tagtype="tagtype">
    </specificationDialog>
    <!-- 套装产品 -->
    <van-popup v-model:show="isSuit" position="bottom" :style="{ height: '65%' }" :close-on-click-overlay="false" round>
      <div class="popup-suit">
        <div class="popup-top">
          <span class="seat"></span>
          <div class="title">{{ $t("套裝包含產品") }}</div>
          <img class="close" @click="isSuit = false" src="@/assets/icons/ic_Close@2x.png" alt="" />
        </div>
        <div class="popup-main">
          <div class="product-suit" v-for="(item,idx) in list.productSuit" :key="idx">
            <div class="suit-img">
              <img :src="`${imgUrl}${item?.picturePath}`" alt="" />
            </div>
            <div class="suit-info">
              <div class="info-top">
                <div class="name">{{ item.productName }}</div>
                <!-- <div class="package">4g/支</div> -->
              </div>
              <div class="info-bottom">
                <div class="num">数量 {{ item.count }}</div>
                <div class="check" @click="handleSkipDetail(item.searchProductId)">查看<van-icon name="arrow" size="16px"
                    color="#90D12E" /></div>
              </div>
            </div>
          </div>
        </div>
        <div class="seat-height"></div>
        <div class="popup-bottom">
          <div class="confirm" @click="isSuit = false">{{ $t("確認") }}</div>
        </div>

      </div>
    </van-popup>
    <discountDialog ref="discountDialogRef" :promotes="promotesList" :list="canGetCouponsNew"
      @success="handleSuccess" />
  </div>
</template>

<script>
import { getProduct, getProductcreateTempOrder } from "@/api/productdetails.js"
import { getWxOauth } from "@/api/user.js"
import mixins from "@/utils/mixins.js"
import config from "@/utils/config.js"
import { getToken } from "@/utils/token"
import Cookies from "js-cookie"
import discountDialog from "@/components/discountDialog/index.vue"
import limitCouponLayout from "@/components/limit-coupon-layout/index.vue"
import { mapState } from "vuex"
export default {
  mixins: [mixins],
  components: {
    // specificationDialog
    discountDialog,
    limitCouponLayout
  },
  data () {
    return {
      list: {},
      selectPhone: "",
      detailId: "",
      isArgument: false,
      isSuit: false,
      isShowProductName: false,
      isSpecificationDialog: false,
      isSpecificationDialog1: false,
      isAuth: {},
      submitGoodsList: {
        goods: [],
        isFromShoppingCart: false,
        isPreOrder: false
      },
      showTop: 0,
      isAppTip: true,
      imgUrl: config.imgUrl,
      tagtype: null,
      days: 0,
      hours: 0,
      minutes: 0,
      count: 0,
      canGetCouponsNew: [],
      promotes: []

    }
  },
  computed: {
    isTextOverflow () {
      const el = this.$refs.productText
      return el?.scrollHeight >= el?.clientHeight && el?.clientHeight > 72
    },
    promotesList () {
      const modifiedArray = this.promotes.map((item, index, array) => {
        // 初始化一个变量来跟踪上一个元素的tag
        let previousTag = index > 0 ? array[index - 1].tag : null

        // 如果当前元素的tag与上一个元素的tag相同，则将其更改为空字符串
        if (previousTag === item.tag) {
          return { ...item, tag: "" }
        }
        // 否则返回原始项
        return item
      })
      console.log('modifiedArray', modifiedArray)

      return modifiedArray
    },
    ...mapState("shopCart", ["cartCount"]),
  },
  watch: {
    "$route.params": {
      handler: function (data) {
        console.log('data', this.$route)
        if (data.id && this.$route.name == "Product") {
          this.init()
        }
      },
      deep: true
    },
  },
  async created () {
    this.init()
  },
  mounted () {
    const element = this.$refs.productName
    this.isShowProductName = this.isTextOverflow
    this.tagtype = this.$route.query?.Tagtype
    if (!!getToken()) {
      this.getCartcount()
    }
  },
  methods: {
    handlegoCart(){
      this.$router.push('/shopCart')
    },
    getCartcount () {
      this.$store.dispatch("shopCart/getCartCount")
    },
    handleSuccess (id) {
      this.canGetCouponsNew.forEach(ele => {
        if (ele.id === id) {
          ele.isGet = true
        }
      })
    },
    handleopenfullgift () {
      this.$refs.discountDialogRef.show()
    },
    async init () {
      this.detailId = this.$route.params.id
      window.localStorage.setItem("productPath", this.$route.fullPath)
      await this.getList()
      this.selectPhone = this.list?.selectStandard.id
      this.promotes = this.list?.promotes || []
      this.canGetCouponsNew = this.list?.canGetCouponsNew || []
      this.list.page = this.appendDomainToImgSrc(
        this.list.page,
        config.imgUrl.replace("/upload", "")
      )
      this.list.page = this.replaceLinks(
        this.list.page,
        config.imgUrl.replace("/upload", "")
      )
      this.isSpecificationDialog1 = true
      if (this.list.promotionDisplay?.countDown == 1) {
        this.countdownStart()
      }
    },
    countdownStart () {
      const { days, hours, minutes, seconds } = this.convertSecondsToDHMS(this.list.promotionDisplay.endTime)
      this.days = days
      this.hours = hours
      this.minutes = minutes
      this.count = seconds
      this.timer = setInterval(async () => {

        if (this.count > 0) {
          this.count -= 1
        } else {
          if (this.minutes > 0) {
            this.minutes =
              this.minutes - 1
            this.count = 59
          } else {
            if (this.hours > 0) {
              this.hours =
                this.hours - 1
              this.minutes = 59
              this.count = 59
            } else {
              if (this.days > 0) {
                this.days =
                  this.days - 1
                this.hours = 23
                this.minutes = 59
                this.count = 59
              } else {

                clearInterval(this.timer)
              }
            }
          }
        }
      }, 1000)
    },
    convertSecondsToDHMS (seconds) {
      var days = Math.floor(seconds / (3600 * 24))
      seconds -= days * 3600 * 24
      var hours = Math.floor(seconds / 3600)
      seconds -= hours * 3600
      var minutes = Math.floor(seconds / 60)
      seconds -= minutes * 60
      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      }
    },
    handleOpenApp () {
      var u = navigator.userAgent
      var isAndroid = /Android/.test(u) //android终端
      var isiOS = /iPhone|iPad|iPod/.test(u) && /AppleWebKit/.test(u) //ios终端
      console.log(u)
      if (isAndroid) {
        window.open("https://play.google.com/store/apps/details?id=com.rith.shop", '_blank')

      } else if (isiOS) {
        window.location.href =
          "https://apps.apple.com/hk/app/e-rith/id6503116009"
      }
    },
    hideTop () {
      this.isAppTip = false
    },
    formatDate (value) {
      if (!value) return ""

      const year = parseInt(value.substring(0, 4), 10)
      const month = parseInt(value.substring(4, 6), 10) // 月份是从0开始的
      const day = parseInt(value.substring(6, 8), 10)
      // 解析传入的时间戳字符串
      if (!year || !month) return "-"
      // 返回格式化的日期字符串
      return `${year}.${month || ""}${day ? "." + day : ""}`
    },
    replaceLinks (text, domain) {
      // 正则表达式匹配 href 属性中的路径
      const regex = /href="(\/upload\/files\/file[^"]*)"/g
      // 替换函数
      return text.replace(regex, (match, path) => {
        // 在路径前添加域名
        return match.replace(path, `${domain}${path}`)
      })
    },
    async handleConfirm (data) {
      console.log(data)
      const tagtype = this.$route.query?.Tagtype
      if (tagtype) {
        this.submitGoodsList.tagtype = tagtype
        this.submitGoodsList.goods = data.map(item => {
          return {
            searchProductId: item.searchProductId,
            count: item.amount,
            tagtype
          }
        })
      } else {
        this.submitGoodsList.goods = data.map(item => {
          return {
            searchProductId: item.searchProductId,
            count: item.amount,
          }
        })
      }




      const res = await getProductcreateTempOrder(this.submitGoodsList)
      if (res.resultID === 1200) {
        this.$router.push("/order/confirm2")
      }
    },
    handlespecificationClose () {
      this.isSpecificationDialog = false
    },
    // 查看所有
    handleViewProductName () {
      this.$refs.productName.style.overflow = "auto"
      this.$refs.productName.style.display = "block"
    },
    // 立即購買
    handleBuyProduct (type) {
      this.isAuth = { isAuth: this.list.isAuth, text: this.list.text, btnType: type }
      this.isSpecificationDialog = true
    },
    // 去登录
    gotoLogin () {
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    handleOpenArgument () {
      this.isArgument = true
    },
    // 套装产品
    handleOpenSuit () {
      this.isSuit = true
    },
    // 跳转详情
    handleSkipDetail (data) {
      this.isSuit = false
      this.$router.push(`/product/${data}`)
    },
    appendDomainToImgSrc (htmlString, domain) {
      // 正则表达式匹配<img>标签的src属性
      const imgSrcPattern = /<img\s+[^>]*src\s*=\s*["']([^"']+)["'][^>]*>/g

      // 回调函数，用于替换匹配到的src值
      function replaceSrc (match, src) {
        // 检查src是否以http或https开头
        if (src.startsWith('http://') || src.startsWith('https://')) {
          return match // 如果是，则返回原始字符串
        }

        // 拼接域名和原src路径
        const newSrc = domain + src
        // 重新构造<img>标签，确保其他属性不变
        return match.replace(src, newSrc)
      }

      // 使用正则表达式替换所有匹配到的src值
      const resultHtml = htmlString.replace(imgSrcPattern, replaceSrc)

      return resultHtml
    },
    async handleAreaCode (item) {
      this.selectPhone = item.id
      this.detailId = item.searchProductId
      const tagtype = this.$route.query?.Tagtype
      const res = await getProduct({ id: this.detailId, tagtype })
      if (res.resultID == 1200) {
        this.list = res.data
      }
    },
    async getList () {
      const tagtype = this.$route.query?.Tagtype
      const res = await getProduct({ id: this.$route.params.id, tagtype })
      if (res.resultID == 1200) {
        this.list = res.data
      }
    },
    sumZore (sum) {
      if (sum < 10) {
        return "0" + sum
      } else {
        return sum
      }
    },
  },
  beforeDestroy () {
    this.isSpecificationDialog1 = false
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  beforeUnmount () {
    this.isSpecificationDialog1 = false
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
}
</script>

<style lang="scss">
.my-swipe {
  min-height: 375px;
}

.product-info {
  padding-bottom: 80px;
}

.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}

.product-content {
  padding: 16px 0;
  background-color: #fff;
  margin-bottom: 8px;

  .price-text {
    padding: 0 16px;
    font-weight: 800;
    font-size: 18px;
    color: #e84935;
    line-height: 30px;
  }

  .product-content-title {
    padding: 0 16px;
    font-weight: 500;
    font-size: 16px;
    color: #1a1a1a;
    line-height: 24px;
    position: relative;

    .product-name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
    }

    img {
      width: 16px;
      position: absolute;
      right: 0;
      bottom: 4px;
    }
  }

  .activity {
    width: 375px;
    height: 116px;
    padding: 8px 6px;
    margin-top: 12px;
    box-sizing: border-box;
    overflow: hidden;

    .time {
      margin-bottom: 8px;
      padding-right: 9px;
      height: 24px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      line-height: 16px;
      font-size: 11px;

      .day,
      .hour,
      .minute,
      .second {
        width: 16px;
        height: 16px;
        background: #FFFFFF;
        border-radius: 2px 2px 2px 2px;
        line-height: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 11px;
        text-align: center;
        margin: 0 2px;
      }

      span {
        color: #FFFFFF;

        &:first-child {
          margin-right: 6px;
        }
      }
    }

    .amountmoney {
      padding: 10px 0 0 10px;

      .dis-price {
        display: flex;
        align-items: center;

        .price {
          font-family: PingFang SC, PingFang SC;
          font-weight: 800;
          font-size: 22px;
          color: #E84935;
          line-height: 30px;
          margin-right: 6px;
        }

        .discounts {
          width: 52px;
          padding-left: 7px;
          box-sizing: border-box;
          height: 19px;
          background: url(~@/assets/image/discounts.png) 100% 100% no-repeat;
          background-size: cover;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 9px;
          color: #FFFFFF;
          line-height: 10px;

          span {
            font-family: OPPOSans, OPPOSans;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 21px;
            text-stroke: 0px #FFFFFF;
            text-align: left;
            -webkit-text-stroke: 0px #FFFFFF;
          }
        }
      }

      .original {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        line-height: 18px;
      }
    }

  }

  .box-tag {
    padding: 12px 16px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .tag {
      margin-left: 3px;
      padding: 0 2px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 11px;
      color: #E84935;
      line-height: 16px;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid rgba(232, 47, 47, 0.5);
    }
  }

  .product-content-unit {
    padding: 0 16px;
    margin-top: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 22px;

    .vertical {
      margin: 0 8px;
    }
  }

  .product-content-price {
    margin-top: 12px;
    padding: 0 16px;
    display: flex;
    align-items: center;

    .discounts-price {
      color: #e84935;
      font-weight: 800;
      font-size: 22px;
      color: #e84935;
      line-height: 30px;
      margin-right: 8px;
    }

    .original-price {
      text-decoration: line-through;
      font-weight: 400;
      font-size: 13px;
      color: #999999;
    }
  }
}

.product-suit {
  background-color: #fff;
  margin-bottom: 8px;

  .specification-title {
    padding: 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 16px;
    }

    .specification-list {
      padding: 12px 16px 16px;
    }
  }
}

.product-specification {
  background-color: #fff;
  margin-bottom: 8px;

  .specification-title {
    padding: 16px 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 16px;
    }

    .specification-list {
      padding: 12px 16px 16px;
    }
  }

  .phone-list {
    padding: 20px 16px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 105px;

    .actvie {
      border: 2px solid #90d12e !important;
    }

    .phone-item {
      padding: 6px 16px;
      position: relative;
      background: #f8f8f8;
      border-radius: 4px;
      margin-bottom: 12px;
      border: 2px solid transparent;
      margin-right: 12px;

      .phone-text {
        font-size: 16px;
        color: #1a1a1a;
        line-height: 16px;
        text-align: center;
        word-break: break-word;
        max-width: 100%;
      }

      .phone-check {
        position: absolute;
        width: 12px;
        bottom: -1px;
        right: -1px;
      }
    }
  }
}

.getcoupon {
  background-color: #fff;
  margin-bottom: 8px;

  .specification-title {
    padding: 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 16px;
    }

    .specification-list {
      padding: 12px 16px 16px;
    }

    .more {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
    }
  }

  .coupon-list {
    padding-left: 16px;
    width: 100%;
    box-sizing: border-box;

    .coupon-list-item {
      display: flex;
      align-items: center;
      overflow: hidden;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.product-argument {
  background-color: #fff;
  margin-bottom: 8px;

  .specification-title {
    padding: 16px 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 16px;
    }

    .specification-list {
      padding: 12px 16px 16px;
    }

    .more {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
    }
  }
}

.argument-list {
  background-color: #fff;
  padding: 12px 16px 16px;

  .argument-container {
    border: 1px solid #f2f3f3;
  }

  .argument-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f2f3f3;

    &:last-child {
      border-bottom: none;
    }

    .argument-label {
      background-color: #fbfbfb;
      font-weight: 400;
      font-size: 14px;
      color: #1a1a1a;
      // white-space: nowrap;
      border-right: 1px solid #f2f3f3;
      width: 30%;

      .argument-label-pd {
        padding: 12px 16px 12px 8px;
      }
    }

    .argument-content {
      padding: 12px 8px;
      font-size: 14px;
      color: #666666;
      line-height: 22px;
      background-color: #fff;
      flex: 1;
    }
  }
}

.product-detail-img {
  background-color: #fff;

  .product-page {
    padding: 0 16px;
  }

  .product-page>* {
    width: 100% !important;
  }

  .specification-title {
    padding: 16px 16px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 16px;
    }

    .specification-list {
      padding: 12px 16px 16px;
    }
  }
}

.Argument-dialog {
  .close {
    position: absolute;
    width: 24px;
    right: 16px;
    top: 16px;
  }
}

.Argument-header {
  padding: 32px 16px 0;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
}

.product-detail-footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding: 6px 16px;
  width: 100%;
  display: flex;
  align-items: center;

  .product-btn-left {
    display: flex;
    align-items: center;
    margin-right: 8px;

    .product-service,
    .product-cart {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: 0;
      position: relative;
      margin-right: 8px;

      img {
        width: 24px;
        height: 24px;
      }

      .name {
        margin-top: 4px;
        font-weight: 400;
        font-size: 11px;
        color: #1A1A1A;
        line-height: 16px;
      }

      .badge {
        position: absolute;
        right: 0;
        top: 5px;
        transform: translate(50%, -50%);
        min-width: 20px;
        height: 20px;
        line-height: 19px;
        color: #FFFFFF;
        // padding: 0 5px;
        box-sizing: border-box;
        background: #e84935;
        text-align: center;
        border-radius: 50%;
        font-size: 10px;
      }
    }
  }


  .product-detail-footer-buy {
    flex: 1;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    background-color: #90d12e;
    border-radius: 8px;
    padding: 10px;
    margin-right: 8px;
  }

  .product-detail-footer-addCart {
    flex: 1;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    background-color: #FFC400;
    border-radius: 8px;
    padding: 10px;
  }
}

.download-pannel {
  height: 44px;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("~@/assets/image/user/topbg.png") 100% 100% no-repeat;
  background-size: cover;

  .download-banner {
    display: flex;
    width: 70%;
    align-items: center;
  }
}

.download-pannel img,
.download-pannel div {
  vertical-align: middle;
}

.download-pannel .pannel-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}

.download-pannel .pannel-bg img {
  width: 100%;
  height: 100%;
}

.download-pannel .download-close {
  width: 16px;
  margin-left: 2.5%;
}

.download-pannel .download-close img {
  width: 100%;
  height: auto;
}

.download-pannel .download-logo {
  width: 35px;
  margin-left: 5%;
  margin-right: 2.5%;
}

.download-pannel .download-logo img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.download-pannel .download-txt {
  height: 100%;
}

.download-pannel .download-content {
  font-size: 12px;
  display: block;
  position: relative;
}

.download-pannel .download-content .content-up {
  color: #fff;
  font-weight: 500;
}

.download-pannel em {
  font-style: normal;
}

.download-pannel .download-content {
  font-size: 12px;
  display: block;
  position: relative;
}

.download-pannel .download-action {
  text-align: center;
  font-size: 13px;
  color: #fff;
  flex: 1;
}

.download-pannel .font-large {
  font-size: 15px;
}

// .download-pannel div[class^="download-"] {
//   position: relative;
//   z-index: 5;
//   float: left;
//   overflow: hidden;
// }

.download-pannel .download-content .content-down {
  color: #999;
  position: absolute;
  top: 9px;
}

// 套装弹窗
.popup-suit {
  width: 100%;
  height: 100%;
  padding: 20px 16px 40px;
  box-sizing: border-box;
  position: relative;

  .popup-top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 20px;

    .seat {
      width: 24px;
    }

    .title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 24px;
    }

    .close {
      width: 24px;
    }
  }

  .popup-main {
    height: 78%;
    overflow: hidden; //超出隐藏
    overflow-y: auto; //出现水平滚动条

    &::-webkit-scrollbar {
      display: none;
    }

    .product-suit {
      width: 100%;
      background: #F8F8F8;
      border-radius: 8px 8px 8px 8px;
      padding: 8px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;

      .suit-img {
        width: 96px;
        height: 96px;
        background: #ffffff;
        font-size: 0;
        margin-right: 16px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .suit-info {
        width: 215px;
        height: 96px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .info-top {
          .name {
            width: 100%;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 13px;
            color: #1A1A1A;
            line-height: 20px;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .package {
            ont-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            line-height: 18px;
            text-align: left;
          }
        }

        .info-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .num {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #1A1A1A;
            line-height: 18px;
            text-align: left;
          }

          .check {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.4);
            line-height: 18px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .seat-height {
    height: 100px;
    width: 100%;
  }

  .popup-bottom {
    position: fixed;
    left: 16px;
    bottom: 40px;

    .confirm {
      width: 343px;
      height: 44px;
      background: #90D12E;
      border-radius: 8px 8px 8px 8px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 44px;
      text-align: center;
    }
  }
}
</style>
